@import '../../../theme';

.button {
  @extend %subtitle;
  
  background-color: color-feedback(success);
  border: .1rem solid color-feedback(success);
  color: color-neutral(lighter);
  border-radius: border-radius(cta);
  height: 4.5rem;
  width: 100%;

  &:disabled {
    opacity: .5;
    cursor: default;
  }
}
