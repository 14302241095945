@import "../../../theme";

.outOfStock {
  text-transform: none;
  background-color: color-feedback(error);
  color: color-neutral(lighter);
  border-radius: border-radius(modal);
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
