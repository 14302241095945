@import '../../../theme';

.select {
  min-height: 3.6rem;
  width: 20rem;
  border-radius: 3.6rem;
  border: .1rem solid color-neutral(dark);
  transition: border-radius .2s;
  transition-timing-function: ease-in;
  background: color-neutral(lighter);
}
.select.open {
  border-radius: .8rem;
}
.arrow {
  transition: transform .3s;
  width: .8rem;
}
.open .arrow {
  transform: rotate(180deg);
}
.option,
.selectedValue {
  background: transparent;
  border: none;
  width: 100%;
  height: 3.6rem;
  font: inherit;
  text-transform: inherit;
  color: color-neutral(darker);
  padding: 0 1.6rem;
}
.selectedValue {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.label {
  line-height: 1;
}
.option {
  text-align: left;
}
.optionsWrapper {
  transition: max-height .3s;
  height: 20rem;
  overflow: hidden;
  max-height: 0;

  &.open {
    max-height: 20rem;
    overflow-y: scroll;
  }
}
