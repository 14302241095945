@import '../../../theme';

.wrapper {
  @extend %subtitle;
  font-size: 1.8rem;
  border-radius: border-radius(modal);
  box-shadow: 0 0 0 .2rem color-neutral(dark);
  height: 3.5rem;
  width: 8.3rem;

  @media (min-width: 1024px){
    width: 7.4rem;
  }
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
}
.button {
  background: transparent;
  border: 0;
  padding: 0;
  flex: 0 0 33.33%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color .3s;
  font-size: 2rem;
  line-height: .9;

  &:hover {
    background: color-neutral(medium);
  }
}
.amount {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 33.33%;
  line-height: .9;
}