@import "../../../theme";

.card {
  width: 100%;
  cursor: pointer;
  text-align: center;
}
.figure {
  width: 100%;
  height: 0;
  position: relative;
  padding-top: 151.56%;
}
.figure img {
  width: 100%;
  height: 100%;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
}
.productTitle {
  @extend %subtitle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0.8rem 0;
}
.prices {
  text-transform: uppercase;
}
.price {
  font-weight: weight(medium);

  &.showDiscount {
    color: color-feedback(error);
  }
}
.fullPrice {
  color: color-neutral(dark);
  text-decoration: line-through;
  display: inline-block;
  margin-left: 0.5rem;
  font-size: 0.9em;
}
.outOfStock {
  margin: 0.5rem auto;
  padding: 0.3rem 0;
  width: 75%;
}
