@import "../../../theme";

.preload {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: color-neutral(lighter);
  background-image: url(../../../assets/backgrounds/tapumes-producao/farm_na_nuvem_loja_3d_mobile.jpg);
  background-position: center;
  background-size: cover;

  &:hover {
    cursor: pointer;
  }

  &.maintenance {
    background-image: url(../../../assets/backgrounds/tapumes-manutencao/farm_na_nuvem_loja_3d_mobile.jpg) !important;

    &:hover {
      cursor: auto !important;
    }
  }

  @media (min-width: 768px) {
    background-image: url(../../../assets/backgrounds/tapumes-producao/farm_na_nuvem_loja_3d_desktop.jpg);

    &.maintenance {
      background-image: url(../../../assets/backgrounds/tapumes-manutencao/farm_na_nuvem_loja_3d_desktop.jpg) !important;
    }
  }
}
