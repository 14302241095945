@import './theme';

html {
  
  font-size: 10px;

  @media (min-width: 320px) and (max-width: 374px){
    font-size: 6.48px;
  }
  
  @media (min-width: 375px) and (max-width: 1800px){
    font-size: 7.28px;
  }
}

body {
  @extend %bodyText;
  color: color-neutral(darker);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none;
}

#root * {
  pointer-events: all;
}

#root ::-webkit-scrollbar {
  width: .8rem; /* width of the entire scrollbar */
  height: .8rem; /* height of the entire scrollbar */
}
#root ::-webkit-scrollbar-track {
  background: color-neutral(lighter); /* color of the tracking area */
}
#root ::-webkit-scrollbar-thumb {
  background-color: color-neutral(dark);    /* color of the scroll thumb */
  border-radius: border-radius(modal);      /* roundness of the scroll thumb */
  border: .1rem solid color-neutral(dark);  /* creates padding around scroll thumb */
}

#root button {
  cursor: pointer;
}

#root a {
  text-decoration: none;
}