@import "../../../theme";

$cardHeight: 16rem;
$cardMarginTop: 2rem;

@keyframes deletingAnimation {
  from {
    height: $cardHeight;
  }
  to {
    height: 0;
  }
}

@keyframes marginTopAnimation {
  from {
    margin-top: $cardMarginTop;
  }
  to {
    margin-top: 0;
  }
}

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 16rem;
  width: 100%;
  gap: 1.7rem;
  opacity: 1;
  transition: opacity 0.3s;
  animation-name: deletingAnimation;
  animation-duration: 0.5s;
  animation-delay: 0.3s;
  animation-timing-function: ease-in-out;
  animation-play-state: paused;
  animation-fill-mode: forwards;

  &.deleting {
    opacity: 0;
    animation-play-state: running;
  }
}

.card + .card {
  margin-top: $cardMarginTop;
}

.deleting + .card {
  animation-name: marginTopAnimation;
  animation-duration: 0.5s;
  animation-delay: 0.3s;
  animation-timing-function: ease-in-out;
  animation-play-state: paused;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.image {
  flex-grow: 0;
  flex-shrink: 0;
  width: 10.5rem;
  height: 10.5rem;
  @media (min-width: 375px) {
    width: 16rem;
    height: 16rem;
  }
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.infosSection {
  flex: 1;
  min-width: 0;

  .title {
    @extend %subtitle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .price {
    @extend %subtitle;
    text-transform: uppercase;
    margin-top: 0.4rem;
  }

  .controlsWrapper {
    margin-top: 1.4rem;
    display: flex;
    justify-content: space-between;

    .sizeLabel {
      box-shadow: 0 0 0 0.2rem color-neutral(dark);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      height: 3.5rem;
      width: 3.5rem;
      
      flex-grow: 0;
      flex-shrink: 0;
      
      text-transform: uppercase;
      
      line-height: 50%;
      font-size: 2rem;

      .sizeLabelLetter {
        line-height: 0;
        //a fonte usada, em alguns tamanhos, fica desalinhada verticalmente
        //por esse motivo estamos quebrando o padrão, aqui, de usar 
        //rem para setar um tamanho fixo em pixels.
        font-size: 14px;

        &.reduceLabelSize {
          font-size: 8px;
          transform: translateY(-10%);
        }
        &.reduceLabelSize::nth-child(odd) {
          transform: translateY(-10%);
        }
        &.reduceLabelSize::nth-child(even) {
            transform: translateY(10%);
        }
      }
    }

    .buttonAmount {
      flex: 1 1 100%;
      margin-left: 2.1rem;
    }
  }
}
.deleteButton {
  flex-grow: 0;
  flex-shrink: 0;
  display: block;
  align-self: center;
  height: 2.3rem;
  width: 2.3rem;
  background: transparent;
  padding: 0;
  border: 0;
}
.deleteButton svg {
  display: block;
  height: 100%;
  width: 100%;
  stroke: color-feedback(error);
  stroke-width: 0.2rem;
}
.deleteButton:hover svg {
  stroke-width: 0.3rem;
}
.deleteButton svg * {
  stroke: inherit;
  stroke-width: inherit;
}
.outOfStock {
  margin: 0.5rem 0;
  padding-bottom: 0.6rem;
  width: 75%;
}
