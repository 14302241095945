@import '../../../theme';

.modal {
  opacity: 0;
  transition: opacity .3s;

  &.show {
    opacity: 1;
  }
}

.cart {
  --horizontal-padding: 1.6rem;
  @media (min-width: 1024px){
    --horizontal-padding: 3rem;
  }

  background: color-neutral(lighter);
  border: .1rem solid color-neutral(dark);
  border-radius: border-radius(modal);

  position: fixed;
  top: 50%;
  right: 7.8rem;
  transform: translate(100%, -50%);

  height: 80%;
  max-height: 75.9rem;

  width: 100%;
  max-width: 80%;

  display: flex;
  flex-direction: column;

  overflow: hidden;

  transition: opacity .3s, transform .3s;
  transition-timing-function: ease-in;
  opacity: 0;

  @media (min-width: 500px) {
    max-width: 45.1rem;
  }
  @media (min-width: 1024px) {
    right: 14.4rem;
  }

  &.show {
    opacity: 1;
    transform: translate(0, -50%);
    transition-timing-function: ease-out;
  }
}
.header {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex: 0 0 6.1rem;
  
  padding: 0 var(--horizontal-padding);
  width: 100%;

  hr {
    margin: 0;

    position: absolute;
    bottom: 0;
    left: var(--horizontal-padding);
    right: var(--horizontal-padding);
    
    border: 0;
    border-bottom: .1rem solid color-neutral(dark);
    
    background: color-neutral(dark);
    color: color-neutral(dark);
    
    opacity: .2;
  }

  .title {
    @extend %subtitle;
    text-transform: lowercase;
  }

  .closeButton {
    background-color: transparent;
    border: 0;
    display: block;
    height: 1.2rem;
    width: 1.2rem;
    padding: 0;
  }
  .closeButton svg {
    height: 100%;
    width: 100%;
    display: block;
  }
}

.middleSection {
  flex: 1 1 100%;
  min-height: 0;
  padding: 0 var(--horizontal-padding);

  .emptyMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    height: 100%;
    text-align: center;

    h3 {
      @extend %headlineSM;
      color: color-feedback(error);
    }

    p {
      @extend %subtitle;
      margin-top: 3.2rem;
      text-transform: initial;
    }
  }
    
  .productsList {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1.7rem .8rem 1.7rem 0;
  }
}

.bottomSection {
  .bottomSectionTopText {
    padding: 1.8rem var(--horizontal-padding);
    line-height: line-height(md);
    background: color-neutral(light);
    color: color-neutral(dark);
    text-transform: initial;
  }

  .bottomSectionBottomText {
    padding: 1.3rem var(--horizontal-padding);
    line-height: line-height(md);
    background: color-neutral(darker);
    color: color-neutral(light);
    text-transform: initial;
  }
}

.footer {
  background: color-neutral(light);
  padding: 1.4rem var(--horizontal-padding) 3.2rem;
  flex-grow: 0;
  flex-shrink: 0;
  
  .prices {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .priceValue { text-transform: uppercase }
  .finalPrice { font-weight: 500 }

  .subtotal { color: color-neutral(dark) }
  
  .total { 
    @extend %subtitle;
    margin-top: 1.8rem;
  }
}

.callToAction {
  display: block;
  margin: auto;
  margin-top: 4.8rem;
}