@import '../../../theme';

.modal {
  opacity: 0;
  transition: opacity .3s ease-in;
}
.modal.show {
  opacity: 1;
  transition-timing-function: ease-out;
}
.show .gallery {
  transform: translateY(0);
  transition-timing-function: ease-out;
}
.gallery {
  --horizontal-padding: 1.6rem;
  @media (min-width: 1024px){
    --horizontal-padding: 3rem;
  }

  background: color-neutral(lighter);
  border: .1rem solid color-neutral(dark);
  border-radius: border-radius(modal);

  position: fixed;
  width: 80%;
  max-width: 63rem;
  height: 90%;
  max-height: 68.8rem;
  
  @media (min-width: 600px) {
    max-height: 86.6rem;
  }

  background: color-neutral(lighter);

  display: flex;
  flex-direction: column;

  overflow: hidden;

  transform: translateY(-100%);
  transition: transform .3s ease-in;
}

.header {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex: 0 0 6.1rem;
  
  padding: 0 var(--horizontal-padding);
  width: 100%;

  hr {
    margin: 0;

    position: absolute;
    bottom: 0;
    left: var(--horizontal-padding);
    right: var(--horizontal-padding);
    
    border: 0;
    border-bottom: .1rem solid color-neutral(dark);
    
    background: color-neutral(dark);
    color: color-neutral(dark);
    
    opacity: .2;
  }

  .title {
    @extend %subtitle;
    text-transform: lowercase;
  }

  .closeButton {
    background-color: transparent;
    border: 0;
    display: block;
    height: 1.2rem;
    width: 1.2rem;
    padding: 0;
  }
  .closeButton svg {
    height: 100%;
    width: 100%;
    display: block;
  }
}
.filterWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--horizontal-padding);
  position: relative;
  flex: 0 0 6.4rem;
  z-index: 1;
   
  .categoryName {
    @extend %subtitle;
    color: color-feedback(success);
    letter-spacing: .1rem;
    font-weight: weight(medium);
  }
  .categorySelect {
    position: absolute;
    right: var(--horizontal-padding);
    top: 50%;
    margin-top: -1.8rem;
  }
}
.productsListWrapper {
  flex: 1 1 100%;
  position: relative;
  z-index: 0;
  padding: var(--horizontal-padding);
  overflow-x: scroll;
  overflow-y: hidden;

  @media (min-width: 600px){
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
.productsList {
  list-style-type: none;
  display: flex;
  gap: 2.5rem;

  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  
  li {
    min-width: 0;
    flex-shrink: 0;
    width: 28.8rem;

    @media (min-width: 600px) {
      width: initial;
    }
  }
}
.loading {
  $size: 5.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(255,255,255,1);
  height: $size;

  position: absolute;
  bottom: 0;

  @media (min-width: 600px) {
    position: relative;
    bottom: initial;
  }

  img {
    height: $size;
    width: $size;
    object-fit: contain;
    object-position: center;
  }
}
.noProducts {
  @extend %headlineSM;
  flex: 1 1 100%;
  display: flex;  
  align-items: center;
  justify-content: center;
  color: color-feedback(error);
  text-align: center;
}