@import './font-faces';

//font-families
$font-stack-primary: var(--font-family-primary, 'Galano Groteske', sans-serif);

//text-transform
$default-text-transform: var(--default-text-transform, lowercase);

//letter-spacing
$default-letter-spacing: var(--default-letter-spacing, .03rem);

//font-weights
$weights: (
  light: var(--font-weight-light, 300),
  regular: var(--font-weight-regular, 400),
  medium: var(--font-weight-medium, 500),
  bold: var(--font-weight-bold, 700),
);

@function weight($weight-name){
  @return map-get($weights, $weight-name);
}

//font-sizes
$font-sizes: (
  xl: var(--font-size-xl, 4.8rem),
  lg: var(--font-size-lg, 3.2rem),
  md: var(--font-size-md, 2.4rem),
  xxs: var(--font-size-xxs, 1.6rem),
  us: var(--font-size-us, 1.4rem),
  xus: var(--font-size-xus, 1.2rem),
  xxus: var(--font-size-xxus, 1rem),
);

@function font-size($size-name){
  @return map-get($font-sizes, $size-name);
}

//line-heights
$line-heights: (
  xl: var(--line-height-xl, 1.6),
  lg: var(--line-height-lg, 1.5),
  md: var(--line-height-md, 1.33),
  xs: var(--line-height-xs, 1.14),
  xxs: var(--line-height-xxs, 1),
);

@function line-height($line-height-name){
  @return map-get($line-heights, $line-height-name);
}

%font-base-styles {
  font-family: $font-stack-primary;
  text-transform: $default-text-transform;
  letter-spacing: $default-letter-spacing;
}

%headlineLG {
  @extend %font-base-styles;
  font-size: font-size(xl);
  line-height: line-height(lg);
}

%headlineMD {
  @extend %font-base-styles;
  font-size: font-size(lg);
  line-height: line-height(lg);
}

%headlineSM {
  @extend %font-base-styles;
  font-size: font-size(md);
  line-height: line-height(md);
}

%subtitle {
  @extend %font-base-styles;
  font-size: font-size(xxs);
  line-height: line-height(lg);
}

%bodyText {
  @extend %font-base-styles;
  font-size: font-size(us);
  line-height: line-height(xs);
}

%captionMD {
  @extend %font-base-styles;
  font-size: font-size(xus);
  line-height: line-height(md);
}

%captionSM {
  @extend %font-base-styles;
  font-size: font-size(xxus);
  line-height: line-height(xl);
}