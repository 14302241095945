@import '../../../theme';

.modal {
  opacity: 0;
  transition: opacity .3s;
  transition-timing-function: ease-in;

  &.show {
    opacity: 1;
  }
}

.card {

  --horizontal-padding: 1.6em;

  position: relative;
  background: color-neutral(lighter); 
  width: calc(100% - 4.2em);
  max-width: 41.7em;
  overflow: hidden;
  border-radius: border-radius(modal);
  
  font-size: .9rem;

  @media(min-width: 375px) {
    font-size: 1rem;
  }

  @media (min-width: 1024px) {
    max-width: 39.58vh;
  }

  transform: translateY(-100%);
  transition: transform .3s ease-in;
  
  * {
    font-size: 1em;
  }

  &.show {
    transform: translateY(0);
    transition-timing-function: ease-out;
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: var(--horizontal-padding);

  .closeButton {
    color: color-neutral(darker);
    background: none;
    border: 0;
    padding: 0;
    width: 2.3em;
    height: 2.3em;
    display: block;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .buttonInfos {
    height: 6em;
    width: 6em;
    background-color: color-neutral(light);
    border: .1em solid color-neutral(darker);
    border-radius: 0 border-radius(modal) 0 border-radius(modal);
    border-top: 0;
    border-right: 0;
  }
  .buttonPictures {
    height: 6em;
    width: 6em;
    background-color: color-neutral(darker);
    border: .1em solid color-neutral(darker);
    border-radius: 0 border-radius(modal) 0em border-radius(modal);
  }
}

.section {
  position: relative;

  .loading {
    position: relative;
    height: 0;
    width: 100%;
    display: flex;
    align-items: center;    
    overflow: hidden;    
    padding-top: 151.56%;
    
    .loadingIcon {
      position: absolute;
      top: calc((100% - 20em)/2);
      left: calc((100% - 20em)/2);
      height: 20em;
      width: 20em;
      object-fit: cover;
      object-position: center;
      z-index: 0; 
    }
  }
  .photos {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;    
    overflow: hidden;    
    padding-top: 151.56%;

    .productImage{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-position: center;
      z-index: 0; 
      opacity: 0;
      transition: opacity .3s;
      &.imageShown {
        opacity: 1;
      }
    }
    .changeImage {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      padding: var(--horizontal-padding);

      .changeImageLeft{
        transform: rotate(180deg);
        background-color: color-neutral(light);
        height: 4.8em;
        width: 4.8em;
        border-radius: border-radius(cta);
        border: .1em solid color-neutral(dark);
        display: flex;
        justify-content: center;
        align-items: center;
      }    
      .changeImageRight{
        background-color: color-neutral(light);
        height: 4.8em;
        width: 4.8em;
        border-radius: border-radius(cta);
        border: .1em solid color-neutral(dark);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .infosProportion {
    position: relative;
    height: 100%;
    width: 100%;
    padding-top: 151.56%;
    transition: opacity .5s;  
    .infosInternalPadding {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 6.4em 2.1em;
      background: color-neutral(light);
      color: color-neutral(darker);
      font-size: font-size(xxs);
      line-height: line-height(lg);
      
      .infosScrollableArea {

        overflow-y: scroll;
        height: 100%;
        padding-right: 1.3rem;

        .productTitle {
          font-weight: weight(bold);
          margin-bottom: 1.3em;
        }
      }
    }
  }
  .sizesMenu {
    position: absolute;
    bottom: 1.6em;
    width: 100%;
    display: flex;
    gap: .8em;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .buttonSize {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      cursor: pointer;
      width: calc(4em / 1.6);
      height: calc(4em / 1.6);
      font-size: 1.6em;
      background: color-neutral(lighter);
      box-shadow: 0 0 0 .1em color-neutral(darker);
      color: color-neutral(darker);
      padding: 0%;
      border-radius: border-radius(cta);
      text-transform: uppercase;
      position: relative;
      text-align: center;
      
      .sizeText {
        line-height: 0;
        //a fonte usada, em alguns tamanhos, fica desalinhada verticalmente
        //por esse motivo estamos quebrando o padrão, aqui, de usar rem
        //para setar um tamanho fixo em pixels.
        font-size: 14px;

        &.reduceLabelSize {
          font-size: 10px;
          transform: translateY(-10%);
        }
        &.reduceLabelSize::nth-child(odd) {
          transform: translateY(-10%);
        }
        &.reduceLabelSize::nth-child(even) {
          transform: translateY(10%);
        }
      }
      
      &.checked {
        background: color-neutral(darker); 
        border-color: color-neutral(darker);
        color: color-neutral(lighter);
      }
      &.disable {
        background: color-neutral(medium);
        color: color-neutral(light);
        border-color: color-neutral(light);
        cursor: default;
      }
    }
    .sizeCheckbox {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: inherit;
      margin: 0 !important;
      padding: 0;
    }    
  }
}

.footer {
  overflow: hidden;
  padding: 2.4em var(--horizontal-padding);

  .productTitle {
    @extend %subtitle;    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 2em;
    text-transform: lowercase;
    text-align: center;      
    color: color-neutral(darker);
  }
  
  .divPrice {
    display:flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 1.6em;

    .outOfStock {
      font-size: 2.0em;
      padding: .3em;
    }

    .productPrice {
      font-size: 2.2em;
      font-weight: weight(bold);
      text-transform: uppercase;
      color: #565656;
      text-align: center;

      &.discount {
        color: color-feedback(error);
      }

      .productPriceWithoutDiscount {
        color: color-neutral(darker);
        text-transform: none;
        text-decoration: line-through;
        font-weight: weight(regular);
      }
      .installments {
        color: color-neutral(darker);
        text-transform: none;
        font-weight: weight(regular);
      }
    }
  }
  .divSimilars {
    margin-top: 1.6em;
    display:flex;
    justify-content: center;
    align-items: center;
    gap: .8em;

    .buttonSimilarImage {
      display: flex;
      justify-content: center;
      align-items: center;
      border: .05em solid color-neutral(darker);
      border-radius: border-radius(cta);
      width: 3.4em;
      height: 3.4em;
      flex-shrink: 0;
      flex-grow: 0;
      padding: .3em;

      .similarImage {
        width: 100%;
        height: 100%;
        border-radius: border-radius(cta);
        object-fit: cover;
        object-position: center;
      }
    }    
  }
  .divCallToAction {
    margin-top: 1.6em;
    display: flex;
    justify-content: center;
    
    .callToAction {
      color: color-neutral(darker);
      border: .1em solid color-neutral(darker);
      max-width: 28.6em;
      background: color-neutral(light);
      font-size:  font-size(xxs);
      max-width: 28.6em;
      

      &.checked {
        background: color-neutral(darker); 
        border: .1em solid color-neutral(darker);
        color: color-neutral(light);
      }
    }
  }
}

.backgroundOpacity{
  position: absolute;
  top: 0;
  z-index: 1;
  background: rgba(0,0,0,0.4);
  width: 100%;
  height: 100%;
  pointer-events: all;
}

.dialog{
  position: absolute;
  top: 28.5em;
  left: calc((100% - 26.5em)/2);
  z-index: 2;
}

.dialogIcon {
  width: 6.6em;
}

.outOfStockDialog p {
  font-weight: weight(bold);
  color: color-feedback(error);
}