@import "../../../theme";

.menu {
  position: absolute;
  top: 4.9rem;
  right: 2.1rem;

  @media (min-width: 1024px){
    right: 6.5rem;
  }
}

.button {
  width: 4.5rem;
  height: 4.5rem;
  position: relative;
  border-radius: 50%;
  background-color: color-neutral(lighter);
  display: block;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 .2rem color-neutral(medium);
  border-color: transparent;
  transition: transform .3s, background-color .3s;
  transform: scale(1);
  transform-origin: center;

  &:hover {
    transform: scale(.9);
    background-color: color-neutral(medium);
  }

  + .button {
    margin-top: 6.5rem;
  }

  svg {
    display: block;
    width: 60%;
    height: 60%;
  }

  @media (min-width: 1024px) {
    width: 5.5rem;
    height: 5.5rem;
  }
}

.cartItemsAmount {
  background-color: color-feedback(success);
  color: color-neutral(lighter);
  font-size: 1.6rem;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
  transform: translate(30%, -30%);
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  border-radius: 50%;
}