@font-face {
  font-family: 'Galano Groteske';
  src: url('/fonts/Galano Grotesque Alt.otf') format("opentype");
  font-weight: 400;
  font-feature-settings: 'salt' on;
}

@font-face {
  font-family: 'Galano Groteske';
  src: url('/fonts/Galano Grotesque Alt Italic.otf') format("opentype");
  font-weight: 400;
  font-style: italic;
  font-feature-settings: 'salt' on;
}

@font-face {
  font-family: 'Galano Groteske';
  src: url('/fonts/Galano Grotesque Alt Medium.otf') format("opentype");
  font-weight: 500;
  font-feature-settings: 'salt' on;
}

@font-face {
  font-family: 'Galano Groteske';
  src: url('/fonts/Galano Grotesque Alt Medium Italic.otf') format("opentype");
  font-weight: 500;
  font-style: italic;
  font-feature-settings: 'salt' on;
}