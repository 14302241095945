@import '../../../theme';

.dialog {
  background-color: color-neutral(lighter);
  width: 26.5rem;
  border: 0.1rem solid color-neutral(dark);
  border-radius: border-radius(modal);
  padding: 0;
}

.text {
  padding: 1.6rem;
  font-size: font-size(xxs);
  color: color-neutral(dark);
  text-transform: none;
  text-align: center;
}

.divIcon {
  height: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  font-size: 1.8rem;
  text-align: center;
  width: 100%;
  color: color-neutral(dark);
  background: none;
  border: none;
  border-top: 0.1rem solid color-neutral(medium);
  height: 4.5rem;
  
  &.warning {
    color: color-feedback(error);
  }
}
  
